import { Navbar } from "components/navigation/Navbar";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { TextInput } from "components/forms";
import { Spinner } from "components/loaders/Spinner";
import { Button } from "components/buttons";
import { LoginForm, RegisterForm } from "components/forms/Auth";
import { GLogin } from "components/forms/GLogin";

export const Login = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    login(values);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="fixed top-0 w-full">{/* <AlertBanner /> */}</div>
        <div className="max-w-md w-full space-y-8">
          <div>
          <div className="flex justify-center items-center">
              
              <img
                className="w-12 py-4"
                src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                alt="Happy Ticketing Company"
              />
            <div className="ml-2">
              <h2 className="text-3xl font-milkshake text-purple">Happy Ticketing</h2>
            </div>
            </div>
            <h3 className="text-center text-lg font-extrabold">
              Log in to your account
            </h3>
          </div>
          <LoginForm handleSubmit={handleSubmit} loading={loading} />
          <span className="block text-center">
            Or
          </span>

          <GLogin />
        </div>
      </div>
    </>
  );
};

export const Registration = () => {
  const { register } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }) => {
    setLoading(true);
    register(values);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <>
      <Navbar />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="fixed top-0 w-full">{/* <AlertBanner /> */}</div>
        <div className="max-w-md w-full space-y-2">
          <div className="flex justify-center items-center">
              <img
                className="w-12 py-4"
                src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                alt="Happy Ticketing Company"
              />
            <div className="ml-4">
              <h2 className="text-3xl font-milkshake text-purple">Happy Ticketing</h2>
            </div>
          </div>
          <h3 className="text-center text-lg sm:text-xl font-extrabold">
            Create Account
          </h3>
          <RegisterForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};
