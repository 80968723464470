import { useState } from "react";
import { UserForm } from "./UserForm";
import { Button } from "components/buttons";
import { CreditCardIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useAuth } from "providers/AuthContext";
import { reject } from "lodash";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { RequestProps } from "utils/api";
import { makeApiRequest } from "utils/api";
import { User } from "types/User";
import { useNotification } from "providers/NotificationContext";

export const Settings = ({}: {}) => {
  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const { user, setCurrentUser } = useAuth();
  const { showSuccess, showError } = useNotification();
  const stripe = useStripe();
  const elements = useElements();
  const defaultCard = user?.payment_cards.find((card) => card.is_default);
  const paymentCards = reject(user?.payment_cards, (card) => card.is_default);

  const handleAddPaymentCard = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // tokenize and send to api
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      stripe.createToken(cardElement).then((result) => {
        const token = result.token;
        const requestProps: RequestProps = {
          path: "/payment_cards",
          method: "POST",
          params: {
            payment_card: {
              card_token: token?.id,
              user_id: user?.id,
            },
          },
        };
        makeApiRequest(requestProps)
          .then((res) => {
            const updatedUser: User = res.data;
            setCurrentUser(updatedUser);
            setShowCardForm(false);
            showSuccess("Card added successfully");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="w-full border-b border-gray-300 pb-8">
            <h3 className="font-extrabold my-4 text-lg">
              Personal Information
            </h3>
            <UserForm />
          </div>
          {/* <div className="w-full border-b border-gray-300 pb-8">
            <h3 className="font-extrabold mt-4 text-lg">Notification Preferences</h3>
            <div className="w-1/2 flex justify-between items-center py-2">
              <label htmlFor="sms-checkbox" className="cursor-pointer">SMS Messaging</label>
              <input id="sms-checkbox" className="w-5 h-5" type="checkbox" />
            </div>
          </div> */}
          <div className="flex justify-between items-center">
            <h3 className="font-extrabold text-lg">Saved Cards&nbsp;</h3>
            <Button
              trailingIcon={PlusIcon}
              onClick={() => setShowCardForm(true)}
              variant="link"
              type="submit"
              className=""
            >
              Add New Card
            </Button>
          </div>
          {defaultCard ? (
            <div className="p-5 bg-gray-100 border border-gray-200 mt-4">
              <div className="flex justify-between items-center">
                <span className="font-extrabold">
                  <span className="capitalize">{defaultCard.card_type}</span>{" "}
                  ending in {defaultCard.last_4}
                </span>
                <CheckIcon className="w-8 h-8 text-green-500" />
              </div>
              <ul className="flex mt-4">
                <span>
                  {paymentCards.map((paymentCard) => (
                    <li className="flex items-center gap-2">
                      <CreditCardIcon className="w-5" />{" "}
                      <span className="capitalize">
                        {paymentCard.card_type}
                      </span>{" "}
                      ending in {paymentCard.last_4}{" "}
                    </li>
                  ))}
                </span>
              </ul>
            </div>
          ) : null}
          {showCardForm && (
            <div className="mt-4">
              <form onSubmit={handleAddPaymentCard}>
                <CardElement className="h-10 flex flex-col justify-center px-4 w-full border border-gray-400 shadow-sm" />
                <Button type="submit" className="mt-2">
                  Add Card
                </Button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
