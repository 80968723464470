import { Combobox, Transition } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { debounce, set } from "lodash";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GooglePlacesAutocompleteProps, {
  Option,
} from "react-google-places-autocomplete/build/types";
import { GooglePlacePrediction } from "types/Location";
import { makeApiRequest } from "utils/api";
export type PropsValue<T> = T | null | undefined;
export const PlacesAutoComplete = ({
  onChange,
}: {
  onChange: (value: string) => void;
}) => {
  const [showResults, setShowResults] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 700);
  const { data } = useQuery({
    queryKey: ["places", debouncedSearch],
    queryFn: () => {
      return makeApiRequest({
        path: `/location_search`,
        params: {
          search: debouncedSearch,
        },
      });
    },
    retry: false,
    staleTime: 1000,
    enabled: !!debouncedSearch,
  });

  const placePredictions = data?.data.predictions || [];

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Make API request to get location details based on latitude and longitude
          makeApiRequest({
            path: "/location",
            params: {
              latitude: latitude.toString(),
              longitude: longitude.toString(),
            },
          }).then(async (response) => {
            const location = response.data.location;
            setSearch(`${location.city}, ${location.state}`);
            // set a users location item in localstorage in browser, this is different than
            // a search location. The user location will be used at checkout to determine tax rates.
            localStorage.setItem("userLocation", JSON.stringify(location));
          });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (onChange && search.length > 0) {
      onChange(search);
    }
  }, [search]);

  return (
    <div className="w-1/2 md:w-1/3 lg:w-[20%] relative">
      <input
        value={search}
        placeholder="Location"
        onChange={(e) => setSearch(e.target.value)}
        onBlur={() => setShowResults(false)}
        onFocus={() => setShowResults(true)}
        type="text"
        className="focus:outline-none focus:ring-0 focus:border-none w-full shadow-md border-r-0 border-gray-200 bg-white text-xl p-4 bg-transparent placeholder:text-gray-400 md:rounded-tl-md md:rounded-bl-md"
      />

      <Transition
        show={showResults}
        enter="transition ease-in-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-[200%] sm:w-80 sm:max-w-lg bg-white border border-gray-200 rounded absolute top-full py-2 z-10 shadow-lg">
          <div
            onClick={handleCurrentLocationClick}
            role="button"
            className="hover:bg-gray-50 w-full p-4 flex gap-2 cursor-pointer"
          >
            <PaperAirplaneIcon className="w-6 text-purple" />
            <span className="text-purple">Current Location</span>
          </div>
          {placePredictions.map((prediction: GooglePlacePrediction) => {
            return (
              <div
                onClick={() => setSearch(prediction.description)}
                role="button"
                className="hover:bg-gray-50 w-full p-4 flex gap-2 cursor-pointer"
              >
                <span>{prediction.description}</span>
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
};
