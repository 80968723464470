import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppProviders from 'providers';
import "react-datepicker/dist/react-datepicker.css";
import HappyNotification from 'components/Notification';
import {ErrorBoundary} from 'react-error-boundary';
import { renderErrorPage } from 'pages/error';
import { ConsentCookieBar } from 'components/ConsentCookieBar';
import * as Sentry from "@sentry/react";
import Intercom from "@intercom/messenger-js-sdk"
import { HappySupportGuy } from 'components/HappySupportGuy';


// Intercom({
//   app_id: "d9redety"
// })

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://fccf0fa42d0eebee4d6e9f7b66b240a2@o4507228119695360.ingest.us.sentry.io/4507228134244352",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/gethappyticketing\.com\/.*/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={renderErrorPage}>
      <AppProviders>
        <HappySupportGuy />
        <HappyNotification />
        <App />
        <ConsentCookieBar />
      </AppProviders>
    </ErrorBoundary>   
  </React.StrictMode>
);