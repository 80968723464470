import { Cookies } from "react-cookie";
import { Button } from "./buttons";
import { useState } from "react"; // Import the useState hook

const cookies = new Cookies();
type ConsentCookie = {
  accepted: boolean;
};
export const ConsentCookieBar = ({}: {}) => {
  const [cookieAccepted, setCookieAccepted] = useState<boolean>(false); // Add a state variable to track the cookie acceptance

  const consentCookie = cookies.get("happyCookieConsent");
  if (consentCookie && consentCookie.accepted) {
    return null;
  }

  const handleAcceptClick = () => {
    cookies.set("happyCookieConsent", { accepted: true }, { path: "/" });
    setCookieAccepted(true); // Update the state variable to trigger a rerender
  };

  return (
    <div className="fixed z-50 bottom-0 w-full py-4 flex items-center px-4 bg-purple text-white">
      <span className="w-3/4 text-sm">
        🍪 Snack time! We use cookies to enhance your experience. Click "Accept"
        if it's cool to make browsing sweet! 🌟
      </span>

      <Button data-testid="accept-cookie" onClick={handleAcceptClick} variant="pink">
        Accept
      </Button>
    </div>
  );
};
