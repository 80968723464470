import { Button } from "components/buttons";
import { TextInput } from "components/forms";
import { Navbar } from "components/navigation/Navbar";
import { useNotification } from "providers/NotificationContext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "utils/api";

export const ResetPassword = ({}: {}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { showSuccess, showError } = useNotification();
  const location = useLocation();
  const pwt = new URLSearchParams(location.search).get("pwt");
  const handleSubmit = () => {
    if (password !== passwordConfirmation) {
      showError("Passwords do not match");
      return;
    }

    if (!pwt) {
      showError("Invalid reset password token");
      return;
    }

    if (!password || password.length < 8) {
      showError("Please enter a password over 8 characters");
      return;
    }

    makeApiRequest({
      path: "/reset_password",
      method: "POST",
      params: { pwt, password: password },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Password successfully reset. Please log in.");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.status === 404) {
          showError("No account found with that email address");
          return;
        }
        showError("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div className="absolute top-0 w-full">
        <Navbar />
      </div>
      <div className="w-80">
        <span className="block mb-5 text-2xl font-bold">
          Reset Your Password
        </span>
        <div className="flex flex-col gap-4">
          <TextInput
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
            name="password"
            value={password}
            label="Password"
            type="password"
          />

          <TextInput
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="New Password Confirmation"
            name="password-confirmation"
            value={passwordConfirmation}
            label="Confirm Password"
            type="password"
          />
          <Button onClick={handleSubmit} className="mt-5">
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ForgotPassword = ({}: {}) => {
  const [email, setEmail] = useState("");
  const { showSuccess, showError } = useNotification();
  const navigate = useNavigate()
  const handleSubmit = () => {
    if (!email) {
      showError("Please enter your email address");
      return;
    }

    makeApiRequest({
      path: "/forgot_password",
      method: "POST",
      params: { email },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Password reset link sent to your email");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.status === 404) {
          showError("No account found with that email address");
          return;
        }
        showError("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div className="absolute top-0 w-full">
        <Navbar />
      </div>
      <div className="w-80">
        <span className="block mb-5 text-2xl font-bold text-center">
          Reset Your Password
        </span>
        <TextInput
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
          name="email"
          value={email}
          label="Email"
          type="email"
        />
        <Button onClick={handleSubmit} className="mt-5">
          Send Reset Email
        </Button>
      </div>
    </div>
  );
};
