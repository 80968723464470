import { Button } from "components/buttons";
import { Navbar } from "components/navigation/Navbar";
import { useAuth } from "providers/AuthContext";
import { Link } from "react-router-dom";
export const CheckoutConfirmation = ({}: {}) => {
  const { user } = useAuth();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      {/* <Navbar /> */}
      <div className="flex justify-center items-center">
        <img
          className="w-12 mr-2"
          src={
            "//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
          }
        />
        <div>
          <span className="text-4xl font-milkshake">Nice!</span>
        </div>
      </div>
      <div className="p-5 text-center">
        Your tickets are on the way. Check your email for order confirmation and
        a link to your tickets. You can also{" "}
        <Link className="text-pink font-extrabold" to="/account/tickets">
          view your tickets
        </Link>{" "}
        here.
      </div>
      <Link to="/account">
        <Button>Back to Account Home</Button>
      </Link>
    </div>
  );
};
