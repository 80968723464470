import { AnimatePresence } from "framer-motion";
import React from "react";
import { Routes, useLocation } from "react-router-dom";

export const AnimatedRoutes = ({children}:{children: React.ReactNode}) => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {children}
      </Routes>
    </AnimatePresence>
  )
}