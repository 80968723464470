import React from "react";
import cn from 'classnames'

// const ButtonVariantStyles = {
//   white: 'inline-flex items-center justify-center rounded border border-mono-400 bg-mono-white px-10 h-12 text-mono-700 shadow-sm hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   gray: 'inline-flex items-center justify-center rounded border border-dark-blue-gray bg-blue-gray px-10 h-12 text-mono-700 shadow-sm hover:bg-dark-blue-gray focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   blueOutline: 'inline-flex items-center justify-center rounded-lg border-2 border-light-blue bg-transparent px-10 h-12 text-light-blue shadow-sm hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-light-blue focus:ring-offset-2',
//   pillPrimary: 'inline-flex justify-center items-center px-10 h-12 border border-transparent text-base text-mono-white rounded-full shadow-sm text-white bg-light-blue hover:bg-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue',
//   pillSecondary: 'inline-flex justify-center items-center px-10 h-12 border border-light-blue text-base rounded-full shadow-sm text-light-blue bg-transparent hover:bg-mono-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue',
//   link: 'inline-flex items-center text-light-blue bg-transparent border-0 font-normal',
// }

const ButtonVariantStyles = {
  primary: 'inline-flex items-center justify-center bg-purple text-white h-10 px-8',
  pink: 'inline-flex items-center justify-center bg-pink text-white h-10 px-8',
  link: 'inline-flex items-center text-pink bg-transparent border-0 font-extrabold',
  default: 'inline-flex items-center justify-center bg-transparent py-3 px-3 text-lightPurple border-lightPurple border-2 hover:bg-lightPurple hover:text-white hover:border-transparent transition-all',
}

const baseStyles = `font-bold font-proxima leading-5 hover:brightness-110 active:translate-y-0.5 rounded-lg`
const loadingStyles = 'cursor-default'
const disableStyles =
  'text-gray-500 bg-gray-300 border-gray-300 cursor-not-allowed'

type Props = {
  variant?: keyof typeof ButtonVariantStyles
  loading?: boolean
  children?: React.ReactNode
  trailingIcon?:  React.ElementType<any>
  leadingIcon?: React.ElementType<any>
} & React.ButtonHTMLAttributes<HTMLButtonElement>

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      children,
      type,
      disabled,
      loading = false,
      variant = 'default',
      className,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      ...baseAttribs
    } = props

    const style = cn(
      baseStyles,
      disabled ? disableStyles : ButtonVariantStyles[variant],
      {
        [loadingStyles]: loading,
      },
      className
    )
    return (
      <button
        ref={ref}
        className={style}
        disabled={disabled || loading}
        type={type}
        {...baseAttribs}
      >
        {loading && 'Loading...'}
        {LeadingIcon && <LeadingIcon className="-ml-1 mr-3 h-5 w-5" />}
        {children}
        {TrailingIcon && <TrailingIcon className="ml-3 -mr-1 h-5 w-5" />}
      </button>
    )
  }
)