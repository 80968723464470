import * as React from 'react'

type Action = { type: 'open'; payload: NotificationPayload } | { type: 'close' }
type Dispatch = (action: Action) => void
export type NotificationState = {
  open: boolean
  message: string
  level: 'error' | 'success' | 'info' | 'warning'
  duration?: number
}
export type NotificationPayload = Pick<
  NotificationState,
  'message' | 'level' | 'duration'
>

const NotificationContext = React.createContext<{
  state: NotificationState
  dispatch: Dispatch
  showError: (message: string) => void
  showSuccess: (message: string) => void
} | null>(null)

const defaultState: NotificationState = {
  open: false,
  message: '',
  level: 'info',
  duration: 4000
}

export function NotificationReducer(_: NotificationState, action: Action) {
  switch (action.type) {
    case 'open':
      return {
        open: true,
        ...action.payload
      }
    case 'close':
      return defaultState
    default:
      throw new Error(`Unhandled action type`)
  }
}

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(NotificationReducer, defaultState)

  const showError = (message: string) => {
    dispatch({
      type: 'open',
      payload: {
        level: 'error',
        message
      }
    })
  }

  const showSuccess = (message: string) => {
    dispatch({
      type: 'open',
      payload: {
        level: 'success',
        message
      }
    })
  }
  const value = { state, dispatch, showError, showSuccess }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotification() {
  const context = React.useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotification must be used within an NotificationProvider')
  }
  return context
}

export { NotificationProvider, useNotification }
