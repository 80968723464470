import { Navbar } from "components/navigation/Navbar";
import { Container } from "components/layout";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Name } from "./Name";
import { Email } from "./Email";
import { Org } from "./Org";
import { AnimatePresence } from "framer-motion";

export const GetStarted = ({}: {}) => {
  const location = useLocation();
  return (
    <>
      <Navbar />
      <div
        style={{
          background:
            "url(//lvgweb.s3.us-east-2.amazonaws.com/happy/events-bg.jpg) no-repeat center center",
        }}
        className="min-h-screen w-screen"
      >
        <Container>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Name />} />
              <Route path="/email" element={<Email />} />
              <Route path="/finish" element={<Org />} />
              <Route path="*" element={<Navigate to="/get-started" />} />
            </Routes>
          </AnimatePresence>
        </Container>
        {!location.pathname.includes("finish") && (
          <div className="text-center mt-10 text-sm">
            <p>
              Already have a Happy vendor account?{" "}
              <a
                className="text-pink font-bold"
                href={`${import.meta.env.VITE_ADMIN_WEB_URL}`}
              >
                Sign in here.
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
