import { useAuth } from "providers/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { Event } from "types/Event";
import { makeApiRequest, RequestProps } from "utils/api";
import { format } from "date-fns";
import { formatCurrency } from "utils/currency";
import { Order } from "types/Order";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";

export const OrderHistory = ({}: {}) => {
  const { user } = useAuth();
  const requestProps: RequestProps = {
    path: "/orders",
    params: {
      user_id: user?.id,
    },
  };
  const { data } = useQuery({
    queryKey: ["orders", user],
    queryFn: () => makeApiRequest(requestProps),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const orders = data?.data;

  if (orders?.length) {
    return (
      <div className="flex flex-col mt-8 md:max-w-md md:m-auto md:mt-12">
        <div className="flex justify-between">
          <span className="block w-4/5 font-extrabold">Order History</span>
        </div>
        {orders?.map((order: Order) => {
          const event = order.event;
          return (
            <div className="flex items-center gap-2 py-3 border-b border-gray-300">
              <div className="w-1/5">
                <img
                  src={
                    order.event_image_url ||
                    "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                  }
                  alt={event.name}
                  className="w-full rounded-lg border border-gray-300 p-1"
                />
              </div>
              <div className="w-4/5 flex flex-col">
                <div className="w-full flex justify-between">
                  <span className="font-bold">Order #{order.id}</span>
                  <span className="font-bold text-green-500">
                    {formatCurrency(order.price_info.total)}
                  </span>
                </div>
                <div className="text-sm w-full flex justify-between items-center">
                  <span>{event.name}</span>
                </div>
                <div className="text-sm w-full flex justify-between items-center">
                  <span>
                    {format(new Date(order.created_at), "MMM d, yyyy")}
                  </span>

                  <div className="flex gap-2">
                    <Link
                      to={`/orders/${order.uid}`}
                      className="bg-pink/30 text-pink px-3 py-1 text-xs flex items-center hover:text-white hover:bg-pink rounded-full"
                    >
                      Tickets
                    </Link>
                    {/* 
                    <Link
                      to={`/account/orders/${order.id}`}
                      className="bg-purple/30 text-purple px-3 text-xs flex items-center hover:text-white hover:bg-purple rounded-full"
                    >
                      Details
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <span className="block py-4">
      You have placed no orders for this account.
    </span>
  );
};
