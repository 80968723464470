import { Container } from "components/layout";
import Footer from "components/navigation/Footer";
import { format } from "date-fns";
import { sortBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { Venue } from "types/Venue";
import { makeApiRequest } from "utils/api";

export const ViewVenue = ({}: {}) => {
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["venue"],
    queryFn: () =>
      makeApiRequest({
        path: `/venues/${params.id}`,
      }),
  });

  const venue: Venue = data?.data;
  if (venue) {
    return (
      <>
        <div className="w-full h-56 relative sm:h-72 lg:h-80 bg-purple">
          <Container className="bg-opacity-60 bg-black py-5 mt-16 xl:py-12 lg:py-10">
            <div className=" flex flex-col justify-between h-full">
              <div className="w-full">
                <span className="block text-white text-xl font-extrabold sm:text-3xl">
                  {venue.name}
                </span>
                <span className="text-white block text-xs sm:text-base">
                  {/* {format(new Date(venue.start_date), "E, MMM do, Y")} */}
                </span>
              </div>
              <div className="w-full">
                <span className="font-extrabold text-xl text-white block sm:text-3xl">
                  {/* {primaryVenue ? venue.venues[0].name : "Multiple Venues"} */}
                </span>
                <span className="text-white block text-xs sm:text-base">
                  {venue.address_1}, {venue.city},{" "}
                  {venue.state}
                </span>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <h2 className="text-xl font-bold text-purple my-5 py-5">
            Upcoming Events at {venue.name}
          </h2>
          {sortBy(venue.events, "start_date").map((event) => {
            return (
              <div className="flex justify-between items-center w-full border-b border-gray-400 py-3">
                <img
                  src={event.image_url}
                  className="md:block md:w-[135px] md:h-[90px] w-[67px] h-[45px]"
                />

                <div className="w-full md:w-2/3 flex justify-between items-center ml-3">
                  <div>
                    <span className="font-bold text-pink text-sm">
                      {format(new Date(event.start_date), "MMM do")}
                    </span>
                    &nbsp;
                    <span className="text-gray-700 text-sm">
                      @ {format(new Date(event.start_date), "h:mm a")}
                    </span>
                    <br />
                    <span className="font-bold md:text-xl">{event.name}</span>
                  </div>

                  <Link
                    to={`/events/${event.slug}`}
                    className="text-center bg-transparent px-5 py-2 text-purple border border-purple rounded-full font-bold hover:brightness-110 hover:bg-purple hover:text-white"
                  >
                    View <span className="hidden md:inline">Tickets</span>
                  </Link>
                </div>
              </div>
            );
          })}
        </Container>
        <Footer />
      </>
    );
  }
  return null;
};
