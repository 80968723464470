import {
  ArrowRightIcon,
  CheckIcon,
  ClockIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { TicketTransfer } from "types/TicketTransfer";
import { makeApiRequest } from "utils/api";

export const TicketTransfers = ({}: {}) => {
  const { showSuccess, showError } = useNotification();
  const { user } = useAuth();
  const { data } = useQuery({
    queryKey: ["ticket_transfers", user],
    queryFn: () =>
      makeApiRequest({
        path: `/ticket_transfers`,
      }),
    enabled: !!user,
  });

  const ticketTransfers: TicketTransfer[] = data?.data;
  const respondToTransfer = (
    ticketTransfer: TicketTransfer,
    status: "accepted" | "declined"
  ) => {
    makeApiRequest({
      path: `/ticket_transfers/${ticketTransfer.id}`,
      method: "PUT",
      params: {
        status: status,
        encrypted_id: ticketTransfer.encrypted_id,
        encrypted_uid: ticketTransfer.encrypted_uid,
      },
    }).then((res) => {
      if (res.status === 200) {
        showSuccess(
          "Your ticket has been successfully transferred. It should show up in your account shortly."
        );
      }
    });
  };
  return (
    <div>
      <span className="block py-3 text-lg font-bold">Ticket Transfers</span>
      <ul>
        {ticketTransfers?.map((transfer) => {
          const isSender = transfer.sending_user.id === user?.id;
          return (
            <li
              className="flex justify-between items-center border-b border-gray-300 pb-3"
              key={transfer.encrypted_id}
            >
              <span>
                <div className="flex gap-3 items-center">
                  <span>{transfer.sending_user.full_name}</span>
                  <ArrowRightIcon className="w-5 text-green-500" />
                  <span>{transfer.receiving_user_name}</span>
                </div>
                <div className="text-sm">
                  {transfer.event.name}
                  <br />
                  <span className="text-xs text-gray-400">
                    {format(
                      new Date(transfer.event.start_date),
                      "MM/dd/yyyy @ h:mma"
                    )}
                  </span>
                </div>
              </span>
              <div className="flex items-center gap-2">
                {isSender || transfer.status !== "pending" ? (
                  <span className="text-gray-500 px-3 py-2 rounded-full border border-gray-300 flex items-center justify-center gap-2 text-xs">
                    {transfer.status === "accepted" && (
                      <>
                        <span>
                          Accepted on{" "}
                          {format(
                            new Date(transfer.status_updated_at) || "",
                            "MM/dd/yyyy"
                          )}
                        </span>
                        <CheckIcon className="w-4 text-green-500" />
                      </>
                    )}
                    {transfer.status === "declined" && (
                      <>
                        <span>Declined</span>
                        <XMarkIcon className="w-6 text-red-500" />
                      </>
                    )}

                    {transfer.status === "pending" && (
                      <>
                        <span>Pending</span>
                        <ClockIcon className="w-6 text-gray-500" />
                      </>
                    )}
                  </span>
                ) : (
                  <>
                    <span
                      role="button"
                      onClick={() => respondToTransfer(transfer, "accepted")}
                      className="text-green-500 text-xs font-bold hover:brightness-90"
                    >
                      Accept
                    </span>
                    <span
                      role="button"
                      onClick={() => respondToTransfer(transfer, "declined")}
                      className="text-red-500 text-xs font-bold hover:brightness-90"
                    >
                      Decline
                    </span>
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {!ticketTransfers?.length && (
        <div className="text-gray-500 py-5">
          You have no ticket transfers at this time.
        </div>
      )}
    </div>
  );
};
