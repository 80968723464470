import TicketCarousel from "components/carousel/TicketCarousel";
import { Container, Modal } from "components/layout";
import { Navbar } from "components/navigation/Navbar";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { OrderItem } from "types/Order";
import { makeApiRequest } from "utils/api";
import { useNotification } from "providers/NotificationContext";
import { useAuth } from "providers/AuthContext";
import { Ticket } from "types/Ticket";
import { Button } from "components/buttons";
import { useState } from "react";
import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { TransferModal } from "pages/Account/MyTickets";

export const ViewOrder = () => {
  const { user } = useAuth();
  const { uid, orderId } = useParams();
  const navigate = useNavigate();
  const { showError } = useNotification();
  const [showTransferModal, setShowTransferModal] = useState<number | null>(
    null
  );

  const [showEventDescription, setShowEventDescription] =
    useState<boolean>(false);

  const verifyOrder = () => {
    return makeApiRequest({
      path: "/verify_order",
      params: {
        uid,
      },
    }).catch((err) => {
      showError("Could not verify order, please contact support.");
      navigate("/");
    });
  };

  const { data } = useQuery({
    queryKey: ["verify_order"],
    queryFn: () => verifyOrder(),
    enabled: !!user || !!uid,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const order = data?.data;
  if (order) {
    return (
      <>
        {uid ? <Navbar /> : null}
        <Container>
          <TicketCarousel>
            {order.tickets.map((ticket: Ticket) => {
              return (
                <div
                  key={ticket.id}
                  style={{
                    borderColor: ticket.color,
                  }}
                  className={`text-center border-2 pb-10 rounded-tr-lg rounded-tl-lg`}
                >
                  <div
                    style={{
                      backgroundColor: ticket.color,
                    }}
                    className={`w-full py-4 text-white flex gap-2 items-center justify-center rounded-tr-lg rounded-tl-lg -mt-1`}
                  >
                    <img
                      src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                      alt="happy white"
                      className="w-8"
                    />
                    <span className="font-milkshake text-xl">Happy Ticket</span>
                  </div>
                  <span className="my-3 text-lg block font-bold">
                    {order.event.name}
                  </span>
                  <span>
                    {format(
                      new Date(order.event.start_date),
                      "E, MMM do, Y @ h:mm a"
                    )}
                  </span>
                  <span className="block">{order.venue_name}</span>

                  <div className="flex justify-between my-2 items-center">
                    <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                      <span>Name</span>
                      <span className="font-bold">{user?.full_name}</span>
                    </div>
                    <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                      <span>Tier</span>
                      <span className="font-bold">{ticket.tier.name}</span>
                    </div>
                    <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                      <span>Section</span>
                      <span className="font-bold">{ticket.section.name}</span>
                    </div>

                    <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                      <span>Level</span>
                      <span className="font-bold">
                        {ticket.price_level.name}
                      </span>
                    </div>
                  </div>
                  <div className="mt-5">
                    <QRCode className="w-full" value={`${ticket.uid}`} />
                    <span className="block mt-3 text-sm">{ticket.uid}</span>
                  </div>

                  <div className="px-3 mt-3">
                    <div className="flex justify-center gap-3">
                      <Button
                        onClick={() => setShowEventDescription(true)}
                        variant="pink"
                        className="w-1/2"
                      >
                        Info
                      </Button>

                      <Button
                        className="flex gap-2 w-1/2"
                        onClick={() => setShowTransferModal(ticket.id)}
                        variant="pink"
                      >
                        Send <PaperAirplaneIcon className="h-5" />
                      </Button>
                    </div>

                    {ticket.sponsor_logo_url && (
                      <div className="flex justify-center mt-3">
                        <img
                          src={ticket.sponsor_logo_url}
                          alt="sponsor logo"
                          className="w-16 h-auto"
                        />
                      </div>
                    )}
                  </div>

                </div>
              );
            })}
          </TicketCarousel>
        </Container>
        <TransferModal
          showTransferModal={showTransferModal}
          onRequestClose={() => setShowTransferModal(null)}
        />

        <Modal
          onRequestClose={() => setShowEventDescription(false)}
          isOpen={showEventDescription}
        >
          <button className="absolute top-5 right-5">
          <XMarkIcon
            className="h-6"
            onClick={() => setShowEventDescription(false)}
          />
        </button>
        <div className="mt-6">
          <span>{order.event.description}</span>
        </div>
        </Modal>
      </>
    );
  }

  return null;
};
