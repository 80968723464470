import {Cookies} from 'react-cookie'
const cookies = new Cookies()

export const makeAuthHeader = () => {
	const user = cookies.get(import.meta.env.VITE_USER_COOKIE || '');
  
	if (user) {
    return {
			Authorization: `Bearer ${user.jwt}`
    }
	}
  return {};
};