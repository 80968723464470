import { useQuery } from "@tanstack/react-query";
import { Button } from "components/buttons";
import { TextInput } from "components/forms";
import { Container } from "components/layout";
import { Navbar } from "components/navigation/Navbar";
import { format } from "date-fns";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TicketTransfer } from "types/TicketTransfer";
import { makeApiRequest } from "utils/api";

export const NewTicketTransfer = ({}: {}) => {
  const {login} = useAuth();
  const { showError, showSuccess } = useNotification();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [searchParams] = useSearchParams();
  const eid = searchParams.get("eid");
  const euid = searchParams.get("euid");
  const { data, error } = useQuery({
    queryKey: ["ticket_transfer", eid, euid],
    queryFn: () =>
      makeApiRequest({
        path: `/verify_transfer`,
        params: {
          encrypted_id: eid,
          encrypted_uid: euid,
        },
      }).then((res) => {
        const transfer = res.data;
        setEmail(transfer.email);
        return transfer
      }),
    retry: false,
    enabled: !!eid && !!euid,
  });

  const ticketTransfer: TicketTransfer = data

  const handleSubmit = () => {
    if (!email || !password) {
      return showError("Please fill out all required fields");
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: password,
      status: "accepted",
    };
    makeApiRequest({
      path: `/ticket_transfers/${ticketTransfer.id}`,
      method: "PUT",
      params: {
        encrypted_id: eid,
        encrypted_uid: euid,
        ticket_transfer: payload,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Ticket claimed successfully!");
          login({ email, password })
        }
      })
      .catch((err) => {
        const message = err.response.data.message;
        showError(message);
      });
  };

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  if (ticketTransfer) {
    return (
      <>
        <Navbar />
        <div className="py-6 sm:py-10 max-w-sm sm:max-w-lg mx-auto">
          <span className="font-milkshake text-4xl text-pink">Get Happy!</span>
          <h2 className="my-3 text-purple font-bold text-3xl">
            Someone is trying to transfer you a ticket:
          </h2>
          <ul>
            <li>
              <strong>Event:</strong> {ticketTransfer.event.name}
            </li>
            <li>
              <strong>Date:</strong>{" "}
              {format(
                new Date(ticketTransfer.event.start_date),
                "MMMM do, yyyy"
              )}
            </li>
            <li>
              <strong>From:</strong> {ticketTransfer.sending_user.email}
            </li>
          </ul>

          <div className="flex flex-col gap-5 mt-5">
            <div className="flex gap-5">
              <div className="w-1/2">
                <TextInput
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              label="Email Address *"
              placeholder="Email Address"
            />

            <TextInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              label="Password *"
              placeholder="Password"
            />

            <Button onClick={handleSubmit}>Claim Ticket</Button>
          </div>
        </div>
      </>
    );
  }
};
