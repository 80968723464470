import { Button } from "components/buttons";
import { Spinner } from "components/loaders/Spinner";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { Link, useNavigate } from "react-router-dom";
import { makeApiRequest } from "utils/api";
import { motion } from "framer-motion";
import Breadcrumbs from "./Breadcrumbs";

type RequestProps = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  org_name: string | null;
  password: string | null;
};

export const Org = ({}: {}) => {
  const [orgName, setOrgName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  
  const navigate = useNavigate();

  const onHandleContinue = () => {
    const first_name = localStorage.getItem("firstName") || null;
    const last_name = localStorage.getItem("lastName") || null;
    const email = localStorage.getItem("email") || null;
    if (orgName && first_name && last_name && email && password) {
      const apiPayload: RequestProps = {
        first_name: localStorage.getItem("firstName"),
        last_name: localStorage.getItem("lastName"),
        email: localStorage.getItem("email"),
        org_name: orgName,
        password: password
      };
      setLoading(true);
      makeApiRequest({
        method: "POST",
        path: "/onboard_org",
        params: apiPayload,
      })
        .then((res) => {
          setLoading(false);
          setOrgName("")

          if (res.status === 200) {
            // clear local storage and show confirmation
            localStorage.clear();
            setShowConfirmation(true);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    return null;
  };
  useEffect(() => {
    if (!localStorage.getItem('firstName') || !localStorage.getItem('firstName')) {
      navigate('/get-started')
    }

    if (!localStorage.getItem('email')) {
      navigate('/get-started/email')
    }
  }, [])
  return (
    <>  
        {!showConfirmation && <Breadcrumbs />}
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full flex justify-center items-center pt-20">
        <div className="w-full md:max-w-lg">
          {showConfirmation ? (
            <>
              <ConfettiExplosion
                width={window.innerWidth}
                colors={["#f72585", "#7209b7", "#4361ee", "#4cc9f0", "#FFBA08"]}
                duration={7000}
              />
              <h1 className="text-purple text-4xl font-bold text-center">
                Get Happy! <br />
                You’re one step away.
              </h1>
              <div className="mt-10 flex flex-col h-32 md:h-24 justify-between items-center">
                <p className="text-xl text-center">
                  Click below to access your dashboard and start selling! 🤑
                </p>

                <a href={`${import.meta.env.VITE_ADMIN_WEB_URL}`}>
                  <Button variant="pink">Log In to Vendor Account</Button>
                </a>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-purple text-4xl font-bold text-center">
                What's the name of your organization?
              </h1>
              <div className="mt-10 flex flex-col h-60 justify-between">
                <input
                  autoFocus={true}
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  type="text"
                  name="orgName"
                  autoComplete="off"
                  className="w-full h-16 border placeholder:text-xl text-xl rounded-lg"
                  placeholder="Organization Name"
                />
                {orgName && orgName.length > 0 ? (
                  <div className="flex items-end">
                  
                    <div className="grow">
                      <label className="block py-3 font-bold">Enter a password to complete your account</label>
                      <input
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onHandleContinue();
                          }
                        }}
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        name="password"
                        className="w-full h-16 border placeholder:text-xl text-xl rounded-tl-lg rounded-bl-lg"
                        placeholder="Password"
                      />
                    </div>
                    <Button
                      disabled={loading}
                      onClick={onHandleContinue}
                      variant="pink"
                      className="h-16 rounded-tr-lg rounded-none rounded-r-md  rounded-br-lg w-32 relative"
                    >
                      Finish
                      {loading ? <Spinner className=" dark:text-purple text-pink w-5 h-5 absolute -left-12 top-0 bottom-0 m-auto" /> : null}
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};
