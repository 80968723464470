import { Transition } from "@headlessui/react";
import { useState } from "react";

export const Tooltip = ({
  content,
  children,
}: {
  content: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block">
      <button
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className="text-gray-600 focus:outline-none"
      >
        {children}
      </button>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-10 text-center max-w-48 min-w-24 text-xs p-2 mt-2 bg-gray-800 text-white rounded-lg shadow-lg">
          <div>{content}</div>
        </div>
      </Transition>
    </div>
  );
};
