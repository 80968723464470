import { Navbar } from "components/navigation/Navbar";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest, RequestProps } from "utils/api";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { format } from "date-fns";
import { Container } from "components/layout";
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronDoubleLeftIcon,
  FlagIcon,
  MapPinIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import { Button } from "components/buttons";
import { Event, PriceLevel, Section, Tier } from "types/Event";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "providers/AuthContext";
import { sumBy } from "lodash";
import { formatCurrency } from "utils/currency";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ExclamationCircleIcon, TicketIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "components/Tooltip";
import { useNotification } from "providers/NotificationContext";

export const ViewEvent = ({}: {}) => {
  const { showError } = useNotification();
  let [searchParams, setSearchParams] = useSearchParams();
  const { user, setCurrentUser, setShowAuthModal } = useAuth();
  const [selectedTier, setSelectedTier] = useState<Tier | null>(null);
  const params = useParams();
  const navigate = useNavigate();

  const eventRequestParams: RequestProps = {
    path: `/events/${params.id}`,
  };

  const sectionRequestParams: RequestProps = {
    path: `/sections`,
    params: {
      event_id: params.id,
    },
  };

  const { data: eventData } = useQuery({
    queryKey: ["event"],
    queryFn: () => makeApiRequest(eventRequestParams),
    refetchOnWindowFocus: false,
  });

  const { data: sectionData } = useQuery({
    queryKey: ["sections"],
    queryFn: () => makeApiRequest(sectionRequestParams),
    refetchOnWindowFocus: false,
  });

  const event: Event = eventData?.data;
  const sections: Section[] = sectionData?.data;
  const visibleSections = sections?.filter((section) => section.visible);
  const primaryVenue = event?.primary_venue;

  const addItemToCart = (item: PriceLevel) => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }

    const requestProps: RequestProps = {
      path: "/cart_items",
      method: "POST",
      params: {
        section_id: item.section_id,
        cart_item: {
          cart_id: user?.cart.id,
          event_id: item.event_id,
          price: item.price_cents / 100,
          price_level_info: {
            id: item.id,
            name: item.name,
            price: item.price_cents,
          },
        },
      },
    };

    makeApiRequest(requestProps)
      .then((res) => {
        const updatedUser = res.data;
        setCurrentUser(updatedUser);
      })
      .catch((err) => {
        const errorMessage = err.data.error_messages;
        showError(errorMessage);
      });
  };

  const deleteCartItem = (item: any) => {
    const requestProps: RequestProps = {
      path: `/cart_items/${item.id}`,
      method: "DELETE",
    };

    makeApiRequest(requestProps).then((res) => {
      const user = res.data;
      setCurrentUser(user);
    });
  };

  const tiers = event?.tiers || [];
  const visibleTiers = tiers?.filter((tier) => tier.visible);

  useEffect(() => {
    const promoterCode = searchParams?.get("promoter_code");
    if (promoterCode) {
      localStorage.setItem("promoter_code", promoterCode);
    }
  }, []);

  useEffect(() => {
    if (event?.tiers.length && sections?.length) {
      const availableTiers = event.tiers.filter((tier) => !tier.sold_out);
      setSelectedTier(availableTiers[0]);
    }
  }, [event, sections]);

  const sectionsForTier = visibleSections?.filter(
    (section) => section.tier.id === selectedTier?.id
  );

  if (!event || !sections) {
    return null;
  }
  return (
    <>
      <Navbar sticky={true} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="w-full min-h-56 relative sm:min-h-72 lg:min-h-80 overflow-hidden bg-gray-50 border-b border-gray-200">
          {/* <img
            src={event.image_url}
            className="absolute top-0 right-0 left-0 bottom-0 bg-opacity-70 bg-black -z-10 blur-sm"
          />
          <div className="absolute top-0 right-0 left-0 bottom-0 bg-opacity-70 bg-black -z-10" /> */}

          <Container className="py-5 mt-16 xl:py-12 lg:py-10">
            <div className="w-full flex flex-col sm:flex-row items-center gap-4 md:gap-10">
              <div className="w-44 h-44 sm:w-60 sm:h-60 lg:w-80 lg:h-80 rounded-full bg-gradient-to-tr from-pink to-purple p-1 sm:p-2">
                <img
                  src={event.image_url}
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <div className="w-full items-center sm:w-2/3 flex flex-col gap-4 sm:gap-10 justify-between h-full">
                <div className="w-full">
                  <span className="block text-xl text-center sm:text-left font-extrabold md:text-3xl lg:text-5xl">
                    {event.name}
                  </span>
                  <p className="mt-2 sm:text-lg text-center sm:text-left">
                    {event.description}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-1">
                  <span className="font-extrabold mb-2 text-xl block sm:text-2xl text-center sm:text-left">
                    @ {primaryVenue?.name}
                  </span>
                  <span className="justify-center sm:justify-start sm:text-left text-xs sm:text-base flex items-center gap-2">
                    <FlagIcon className="h-4 w-4 inline-block" />
                    <span>
                      Presented By:{" "}
                      <Link
                        className="text-blue font-bold hover:brightness-110"
                        to={`/orgs/${event.organization.id}`}
                      >
                        {event.organization.name}
                      </Link>
                    </span>
                  </span>
                  <span className="justify-center sm:justify-start text-xs sm:text-base flex items-center gap-2">
                    <MapPinIcon className="h-4 w-4 inline-block" />
                    <span>
                      {event.primary_venue.address_1},{" "}
                      {event.primary_venue.city}, {event.primary_venue.state}
                    </span>
                  </span>
                  <span className="justify-center sm:justify-start text-xs sm:text-base flex items-center gap-2">
                    <CalendarDaysIcon className="h-4 w-4 inline-block" />
                    <span>
                      {event.start}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </Container>
        </div>
        {event.open_to_public ? (
          <>
            <Container>
              <Link
                to="/explore"
                className="py-5 font-bold text-pink inline-flex items-center"
              >
                <ChevronDoubleLeftIcon className="w-4 mr-1" />
                Back to Events
              </Link>

              <div>
                {event.instructions && (
                  <p className="p-3 rounded-xl bg-blue/30 text-blue mb-5">
                    {event.instructions}
                  </p>
                )}

                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-purple-500 focus:ring-purple-500"
                    defaultValue={selectedTier?.name}
                    onChange={(e) =>
                      setSelectedTier(JSON.parse(e.target.value))
                    }
                  >
                    {visibleTiers?.map((tier: Tier) => (
                      <option value={JSON.stringify(tier)} key={tier.name}>
                        {tier.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav
                    className="flex items-center space-x-4"
                    aria-label="Tabs"
                  >
                    {visibleTiers?.map((tier: Tier) => {
                      const tierSections = sections.filter(
                        (section) => section.tier.id === tier.id
                      );

                      const isSoldOut =
                        sumBy(tierSections, "available_ticket_count") === 0;

                      if (isSoldOut) {
                        return (
                          <Tooltip content={"Sold Out"}>
                            <span
                              key={tier.name}
                              className="bg-gray-300 rounded-md px-3 py-2 text-sm font-medium opacity-50 cursor-not-allowed"
                            >
                              {tier.name}
                            </span>
                          </Tooltip>
                        );
                      }

                      return (
                        <span
                          onClick={() => setSelectedTier(tier)}
                          role="button"
                          key={tier.name}
                          className={classNames(
                            tier.name === selectedTier?.name
                              ? "bg-purple/30 text-purple"
                              : "text-gray-500 hover:text-gray-700",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={
                            tier.name === selectedTier?.name
                              ? "page"
                              : undefined
                          }
                        >
                          {tier.name}
                        </span>
                      );
                    })}
                  </nav>
                </div>
              </div>
              <p className="py-4">{selectedTier?.description}</p>
            </Container>
            <div className="flex flex-col gap-10">
              <section key={selectedTier?.id}>
                <Container>
                  <div className="flex flex-col pb-28">
                    {sectionsForTier.map((section) => {
                      return (
                        <PriceLevels
                          key={section.id}
                          deleteCartItem={deleteCartItem}
                          addItemToCart={addItemToCart}
                          section={section}
                        />
                      );
                    })}
                  </div>
                </Container>
              </section>
            </div>
          </>
        ) : (
          <Container>
            <div className="py-10 text-center">
              <ExclamationCircleIcon className="h-20 w-20 mx-auto" />
              <p>This event is not open to the public.</p>
              <Link to="/explore" className="text-pink font-bold">
                Back to Events
              </Link>
            </div>
          </Container>
        )}

        <AnimatePresence mode="wait">
          {user?.cart.items.length ? (
            <motion.div
              data-testid="cart"
              key="cart"
              initial={{ translateY: "100%" }}
              animate={{ translateY: 0 }}
              exit={{ translateY: "100%" }}
              className="bg-purple min-h-20 fixed z-20 bottom-0 w-full border-t border-gray-200"
            >
              <Container className="py-5">
                <div className="flex justify-between items-center">
                  <div className="w-1/2">
                    <span className="text-white font-extrabold uppercase">
                      Subtotal&nbsp;
                    </span>
                    <span className="text-gray-300">
                      ({user.cart.items.length} items)
                    </span>
                    <span className="block text-gray-300 font-bold">
                      {formatCurrency(sumBy(user.cart.items, "price_cents"))}
                    </span>
                  </div>

                  <Button
                    data-testid="continue-to-checkout"
                    onClick={() => navigate("/checkout")}
                    trailingIcon={ArrowRightIcon}
                    variant="pink"
                  >
                    Continue
                  </Button>
                </div>
              </Container>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export const PriceLevels = ({
  section,
  addItemToCart,
  deleteCartItem,
}: {
  section: Section;
  addItemToCart: (item: PriceLevel) => void;
  deleteCartItem: (item: PriceLevel) => void;
}) => {
  const { user } = useAuth();
  return (
    <div className="flex flex-col py-3">
      <span className="block font-extrabold">{section.name}</span>
      <p className="text-gray-500 text-sm flex items-center gap-1">
        {section.available_ticket_count === 0 ? (
          <>
            <span>😞</span>
            <span>Sold Out</span>
          </>
        ) : (
          <>
            {/* <TicketIcon className="h-4 w-4 inline-block" /> */}
            {/* <span>{section.available_ticket_count} tickets available</span> */}
          </>
        )}
      </p>
      <p className="text-gray-500">{section.description}</p>
      {section.available_ticket_count > 0 && (
        <div className="flex gap-3 flex-wrap">
          {section.price_levels.map((option, i) => {
            const itemsInCart =
              user?.cart.items.filter(
                (i) => i.price_level_info.id === option.id
              ) || [];
            return (
              <div
                className={classNames(
                  "sm:w-1/3 lg:w-[22%] mt-5 relative w-full justify-center px-4 py-6 border border-gray-300 rounded-lg flex flex-col items-center text-gray-700",
                  itemsInCart.length > 0 ? "bg-gray-200" : "bg-white"
                )}
              >
                  <button
                    className={classNames(
                      "absolute left-3 top-0 bottom-0 m-auto w-full h-full",
                      itemsInCart.length <= 0 && "hidden"
                    )}
                    onClick={() => deleteCartItem(itemsInCart[0])}
                  >
                    <MinusCircleIcon className="h-12 w-12 text-gray-500" />
                  </button>
                  {itemsInCart.length > 0 ? (
                    <div className="font-extrabold text-lg rounded-full h-6 w-6 flex items-center justify-center bg-pink text-white absolute m-auto left-0 right-0 -top-3">
                      <span>{itemsInCart.length}</span>
                    </div>
                  ) : null}
                  <button
                    data-testid={`option-${i}-add-to-cart`}
                    className="absolute right-3 top-0 bottom-0 m-auto"
                    onClick={() => addItemToCart(option)}
                  >
                    <PlusCircleIcon className="text-purple h-12 w-12" />
                  </button>

                <span className="text-sm text-center max-w-36">
                  {option.name}
                  <br />
                  <b>{formatCurrency(option.price_cents)}</b>
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
