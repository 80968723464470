import {Cookies} from 'react-cookie'
const cookies = new Cookies()

type UserCookie = {
  id: string
  jwt: string
}

export const setUser = (payload: UserCookie) => {
  if (!import.meta.env.VITE_USER_COOKIE){ return null }
	return cookies.set(import.meta.env.VITE_USER_COOKIE, payload, {path: '/'});
}

export const getUser = () => {
  if (!import.meta.env.VITE_USER_COOKIE){ return null }
  return cookies.get(import.meta.env.VITE_USER_COOKIE);
}

export const clearUser = () => {
  if (!import.meta.env.VITE_USER_COOKIE){ return null }
	return cookies.remove(import.meta.env.VITE_USER_COOKIE, {path: '/'});
}
