import { Container } from "components/layout";
import Footer from "components/navigation/Footer";
import { Navbar } from "components/navigation/Navbar";

export const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container className="py-10">
        <h1 className="text-3xl font-bold">Privacy Policy</h1>
        <p className="my-3">
          <b>Effective Date: February 20, 2024</b>
        </p>
        <p>
          {/* Our privacy policy is simple. We don't store any of your data. We don't
          track you. We don't sell your data. We don't even want your data. We
          just want you to have a great time at our events. << LOLL */}
          Welcome to Happy Ticketing! We respect your privacy and are committed
          to protecting your personal data. This privacy policy will inform you
          as to how we look after your personal data when you visit our website
          (regardless of where you visit it from) and tell you about your
          privacy rights and how the law protects you.
        </p>
        <div className="flex flex-col gap-10 mt-10">
          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              1. Information We Collect
            </h2>
            <p className="mt-2">
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>
                <strong>Identity Data</strong> includes first name, last name,
                username or similar identifier.
              </li>
              <li>
                <strong>Contact Data</strong> includes email address and
                telephone numbers.
              </li>
              <li>
                <strong>Transaction Data</strong> includes details about
                payments to and from you and other details of products and
                services you have purchased from us.
              </li>
              <li>
                <strong>Technical Data</strong> includes internet protocol (IP)
                address, your login data, browser type and version, time zone
                setting and location, browser plug-in types and versions,
                operating system and platform, and other technology on the
                devices you use to access this website.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              2. How We Use Your Personal Data
            </h2>
            <p className="mt-2">
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>To register you as a new customer.</li>
              <li>
                To process and deliver your orders including managing payments
                and collecting money owed to us.
              </li>
              <li>
                To manage our relationship with you, including notifying you
                about changes to our terms or privacy policy.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              3. Sharing Your Personal Data
            </h2>
            <p className="mt-2">
              We may have to share your personal data with external third
              parties for the purposes of providing our services to you.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              4. Data Security
            </h2>
            <p className="mt-2">
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorized way, altered or disclosed.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              5. Your Legal Rights
            </h2>
            <p className="mt-2">
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">
              6. Changes to This Privacy Policy
            </h2>
            <p className="mt-2">
              We may update this privacy policy from time to time.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900">Contact Us</h2>
            <p className="mt-2">
              If you have any questions about this privacy policy or our privacy
              practices, please contact us at:
            </p>
            <p>Email: help@gethappyticketing.com</p>
          </section>
        </div>
      </Container>
      <Footer />
    </>
  );
};
