import { MutableRefObject, useEffect, useRef } from "react";

export const useInterval = ({
  callback,
  delay,
}: {
  callback: any;
  delay: any;
}) => {
  const savedCallback: MutableRefObject<any> = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};