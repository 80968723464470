import { Button } from "components/buttons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { motion } from "framer-motion";
import classNames from "classnames";

export const Email = ({}: {}) => {
  const [email, setEmail] = useState<string>(
    localStorage.getItem("email") || ""
  );
  const [errors, setErrors] = useState<{
    email: boolean;
  }>({
    email: false,
  });
  const navigate = useNavigate();

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const onHandleContinue = () => {
    if (isValidEmail(email)) {
      localStorage.setItem("email", email);
      navigate("/get-started/finish");
    } else {
      setErrors({
        email: true,
      });
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('firstName') || !localStorage.getItem('firstName')) {
      navigate('/get-started')
    }
  }, [])

  return (
    <>
      <Breadcrumbs />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full flex justify-center items-center pt-20"
      >
        <div className="w-full md:max-w-lg">
          <h1 className="text-purple text-4xl font-bold text-center">What's your email?</h1>
          <div className="mt-10 flex flex-col h-36 justify-between">
            <div className="relative">
              <input
                autoFocus={true}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onHandleContinue();
                  }
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                className={classNames(
                  "w-full h-16 border placeholder:text-xl text-xl rounded-lg",
                  errors.email ? "border-purple border-4" : ""
                )}
                placeholder="Email Address"
              />
            {errors.email ? (
              <span className="text-pink absolute h-7 right-4 top-0 bottom-0 m-auto">Invalid Email</span>
            ) : null}
            </div>
            <Button
              onClick={onHandleContinue}
              variant="pink"
              className="h-16 rounded-lg"
            >
              Continue
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};
