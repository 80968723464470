import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Children, ReactNode, useReducer } from "react";
import { useSwipeable } from "react-swipeable";

const NEXT = "NEXT";
const PREV = "PREV";

type Direction = typeof NEXT | typeof PREV;

interface CarouselState {
  pos: number;
  sliding: boolean;
  dir: Direction;
}

type CarouselAction =
  | { type: Direction; numItems: number }
  | { type: "stopSliding" };

const getOrder = (index: number, pos: number, numItems: number) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const getInitialState = (numItems: number): CarouselState => ({
  pos: 0,
  sliding: false,
  dir: NEXT,
});

const TicketCarousel = ({ children }: { children: ReactNode }) => {
  const numItems = Children.count(children);
  const [state, dispatch] = useReducer(reducer, getInitialState(numItems));
  const slide = (dir: Direction) => {
    if (dir === NEXT && state.pos >= numItems - 1) {
      return null;
    }

    if (dir === PREV && state.pos === 0) {
      return null;
    }
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const transform = () => {
    const offsetX = `translateX(calc(${state.pos} * (-100% - 20px)))`;
    return offsetX;
  };

  return (
    <div {...handlers}>
      <div className="w-full overflow-hidden">
        <div className="flex justify-center gap-3 pb-3 items-center my-3">
          <span role='button' className={state.pos === 0 ? "invisible" : ""}>
            <ChevronLeftIcon className="h-5" onClick={() => slide(PREV)} />
          </span>
          <span>
            {state.pos + 1} of {numItems}
          </span>
          <span role='button' className={state.pos + 1 === numItems ? "invisible" : ""}>
            <ChevronRightIcon className="h-5" onClick={() => slide(NEXT)} />
          </span>
          {/* {state.pos !== 0 ? (
            <SlideButton onClick={() => slide(PREV)}>Prev</SlideButton>
          ) : (
            <span></span>
          )}
          {state.pos < numItems - 1 && (
            <SlideButton onClick={() => slide(NEXT)}>Next</SlideButton>
          )} */}
        </div>
        <div
          className="flex transition-all duration-500"
          style={{
            transform: transform(),
          }}
        >
          {Children.map(children, (child, index) => {
            return (
              <div
                style={{
                  flex: "1 0 100%",
                  flexBasis: "100%",
                  marginRight: "20px",
                  // order: getOrder(index, state.pos, numItems),
                }}
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SlideButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <button
      className="px-5 py-2 bg-pink rounded-full font-bold text-white"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

function reducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default TicketCarousel;
