/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import {
  MusicalNoteIcon,
  PaintBrushIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { MoonIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { ReactComponentElement, useState } from "react";
type CATEGORY_TYPES = "Music" | "Sports" | "Art" | "Night Life" | "All Categories";
export default function CategoryTabs() {
  const [selectedCategory, setSelectedCategory] = useState<CATEGORY_TYPES
  >("All Categories");
  const categories: {
    name: CATEGORY_TYPES;
    icon: React.ComponentType<any> | null;
    current: boolean;
  }[] = [
    { name: "All Categories", icon: null, current: selectedCategory === "All Categories"},
    {
      name: "Sports",
      icon: TrophyIcon,
      current: selectedCategory === "Sports",
    },
    {
      name: "Music",
      icon: MusicalNoteIcon,
      current: selectedCategory === "Music",
    },
    {
      name: "Night Life",
      icon: MoonIcon,
      current: selectedCategory === "Night Life",
    },
    { name: "Art", icon: PaintBrushIcon, current: selectedCategory === "Art" },
  ];
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={categories.find((c) => c.current)?.name as string}
        >
          {categories.map((c) => (
            <option key={c.name}>{c.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {categories.map((c) => (
            <button
              onClick={() => setSelectedCategory(c.name)}
              key={c.name}
              className={classNames(
                c.current
                  ? "bg-purple/20 text-lightPurple"
                  : "bg-gray-200 text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium flex items-center justify-center gap-1"
              )}
              aria-current={c.current ? "page" : undefined}
            >
              {c.icon && <c.icon className="w-4 h-4" />}
              {c.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
