import { Button } from "components/buttons";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { motion } from "framer-motion";
import classNames from "classnames";
import { first } from "lodash";
export const Name = ({}: {}) => {
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const [firstName, setFirstName] = useState<string>(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState<string>(
    localStorage.getItem("lastName") || ""
  );
  const [errors, setErrors] = useState<{
    firstName: boolean;
    lastName: boolean;
  }>({
    firstName: false,
    lastName: false,
  });
  const navigate = useNavigate();
  const onHandleContinue = () => {
    if (!firstName || !lastName) {
      return setErrors({
        firstName: !firstName,
        lastName: !lastName,
      });
    }
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    navigate("/get-started/email");
    // navigate to /email
  };
  useEffect(() => {
    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
    }
  }, []);

  return (
    <>
      <Breadcrumbs />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full flex justify-center items-center pt-20"
      >
        <div className="w-full md:max-w-lg">
          <h1 className="text-purple text-4xl font-bold text-center">What's your name?</h1>
          <div className="mt-10 flex flex-col h-60 justify-between">
            <div className="relative">
              <input
                ref={firstNameInputRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onHandleContinue();
                  }
                }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                name="first_name"
                className={classNames(
                  "w-full h-16 border placeholder:text-xl text-xl rounded-lg focus:ring-2 focus:ring-purple focus:outline-none focus:border-none",
                  errors.firstName ? "border-red-500 border-4" : ''
                )}
                placeholder="First Name"
              />
              {errors.firstName && (
                <span className="text-red-500 absolute right-5 top-0 bottom-0 m-auto h-6">
                  required
                </span>
              )}
            </div>
            <div className="relative">
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onHandleContinue();
                  }
                }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="last_name"
                className={classNames(
                  "w-full h-16 border placeholder:text-xl text-xl rounded-lg focus:ring-2 focus:ring-purple focus:outline-none focus:border-none",
                  errors.lastName ? "border-red-500 border-4" : ''
                )}
                placeholder="Last Name"
              />
              {errors.lastName && (
                <span className="text-red-500 absolute right-5 top-0 bottom-0 m-auto h-6">
                  required
                </span>
              )}
            </div>
            <Button
              onClick={onHandleContinue}
              variant="pink"
              className="h-16 rounded-lg"
            >
              Continue
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};
