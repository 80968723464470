import { Formik, Field, Form } from "formik";
import { TextInput } from ".";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components/buttons";
import { Spinner } from "components/loaders/Spinner";

export const LoginForm = ({
  handleSubmit,
  loading,
  handleLinkClick,
}: {
  handleLinkClick?: () => void;
  handleSubmit: (values: { email: string; password: string }) => void;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const navigateToCreateAccountPage = () => {
    navigate("/register");
  };
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
      }}
    >
      <Form>
        <div className="rounded-md -space-y-px flex flex-col gap-4">
          <Field name="email">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput label="Email" placeholder="Email address" {...field} type="email" />
            )}
          </Field>

          <Field name="password">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput label="Password" placeholder="Password" {...field} type="password" />
            )}
          </Field>
        </div>
        <div className="text-sm flex justify-between mt-4">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (handleLinkClick) return handleLinkClick();
              return navigateToCreateAccountPage()
            }}
            variant="link"
          >
            Create an account
          </Button>
          <Link to="/forgot-password">
            <Button variant="link">Forgot your password?</Button>
          </Link>
        </div>
        <div className="flex items-center justify-between py-6">
          <div className="flex items-center">
            <Button variant="primary" className="mr-2" type="submit">
              {loading ? <Spinner /> : "Log In"}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export const RegisterForm = ({
  handleSubmit,
  loading,
  handleLinkClick,
}: {
  handleLinkClick?: () => void;
  handleSubmit: (values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }) => void;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate("/login");
  };
  return (
    <Formik
      initialValues={{ first_name: "", last_name: "", email: "", password: "" }}
      onSubmit={handleSubmit}
    >
      <Form>
        <div>
          <Field name="first_name">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="First Name"
                placeholder="First name"
                {...field}
                type="text"
              />
            )}
          </Field>
          <div className="mt-2">
            <Field name="last_name">
              {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                <TextInput
                  label="Last Name"
                  placeholder="Last name"
                  {...field}
                  type="text"
                  
                />
              )}
            </Field>
          </div>
          <div className="mt-2">

            <Field name="email">
              {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                <TextInput
                  label="Email"
                  placeholder="Email address"
                  {...field}
                  type="email"
                />
              )}
            </Field>
          </div>
          <div className="mt-2">
          
            <Field name="password">
              {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                <TextInput
                  label="Password"
                  placeholder="Password"
                  {...field}
                  type="password"
                />
              )}
            </Field>
          </div>
        </div>
        <div className="text-sm flex justify-between mt-4">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (handleLinkClick) return handleLinkClick();

              return navigateToLoginPage();
            }}
            variant="link"
          >
            Already a member?
          </Button>
        </div>
        <div className="flex items-center justify-between py-6">
          <div className="flex items-center">
            <Button variant="primary" className="mr-2" type="submit">
              {loading ? <Spinner /> : "Create Account"}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
